.page-wrapper {
  .mpp_module_wrapper {
    margin-top: 20px;
  }
  .discover_more {
    &__product,
    &__product-price {
      .product-price-installment {
        font-weight: normal;
      }
    }
  }
  .page-utilities__account {
    @media #{$medium-up} {
      left: -20px;
    }
    @media #{$xlarge-up} {
      left: 0;
    }
  }
  .mpp {
    &__product {
      .product_brief {
        &__description {
          .product_brief {
            &__misc-flag-spacer {
              display: block !important;
            }
            &__panel {
              margin: 5px 0;
            }
          }
        }
      }
      .product_brief {
        &__buttons-container {
          padding-top: 40px;
        }
      }
    }
    &__header {
      margin-top: 30px;
    }
  }
  .spp {
    .spp-product {
      .product__price--bold {
        margin: 10px;
      }
    }
  }
  .product-full {
    .product__price--bold {
      margin: 5px;
    }
  }
  .utility-nav {
    &__signin-text {
      font-size: 9px;
      width: 65px;
      line-height: 1.5;
      text-align: center;
      padding-top: 16px;
      @media #{$iphone5-portrait} {
        font-size: 6.5px;
        padding-#{$ldirection}: 30px;
      }
    }
  }
}

.product__price--bold {
  font-weight: bold;
}

#cboxLoadedContent,
.checkout__content {
  .social-login {
    &.gnav {
      text-align: center;
      margin-#{$rdirection}: 0;
    }
    &__container {
      text-align: center;
    }
    &__divider {
      @media #{$large-up} {
        width: 100%;
      }
    }
    &__email-opt-in {
      text-align: #{$ldirection};
      margin-top: 15px;
      padding-#{$ldirection}: 25px;
      @media #{$medium-up} {
        padding-#{$ldirection}: 0;
      }
      label {
        text-transform: none;
        letter-spacing: 0.8px;
      }
    }
    &__terms {
      text-align: #{$ldirection};
      font-size: 12px;
      padding-#{$ldirection}: 25px;
      @media #{$medium-up} {
        padding-#{$ldirection}: 0;
      }
    }
  }
  .bisPcIframe,
  .bisMobileIframe {
    height: 375px;
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      .tag-group {
        .pr-label-control {
          &.checked {
            background-color: $color-white;
            &::before,
            &::after {
              #{$rdirection}: 3px;
              #{$ldirection}: auto;
            }
          }
        }
      }
    }
  }
}

.cs-quick-info {
  &__block {
    &--live-chat {
      .el_us_le_chat_live {
        height: 18px;
        background-position: 0 -1792px;
      }
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    margin-bottom: 10px;
  }
}

.lp_radio_button {
  input[type='radio'] {
    ~ label {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}

.email_signup_sucess_popup {
  #cboxWrapper {
    height: 150px !important;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $optimalight;
}

.page-utilities {
  &__account {
    .user-loyalty-state {
      bottom: 0;
      position: absolute;
      font-size: 12px;
      font-weight: 400;
      text-align: #{$rdirection};
      #{$rdirection}: 20px;
      min-width: 375px;
      z-index: 1;
      top: 35px;
    }
  }
  &__signin-text {
    cursor: pointer;
  }
}

.p-w-r {
  .pr-review {
    &-snapshot {
      &-snippets {
        .pr-snippet {
          &-rating-decimal {
            &::after {
              content: $reviews_suffix;
            }
          }
        }
      }
    }
  }
  .tag-group {
    .pr-cb-tag-container {
      input[type='checkbox'] {
        & ~ .label::before,
        & ~ label::before {
          #{$rdirection}: 15px;
          #{$ldirection}: auto;
        }
      }
    }
  }
}

.perfectionist-results {
  .perfectionist-results {
    &__first-branch {
      #{$ldirection}: 3%;
      @media #{$large-up} {
        #{$ldirection}: 39%;
      }
    }
    &__second-branch {
      #{$rdirection}: 1%;
      @media #{$large-up} {
        #{$rdirection}: 3%;
      }
    }
  }
}

.product {
  &-price {
    &--non-sale {
      font-weight: normal;
      text-decoration: line-through;
      margin-right: 5px;
    }
    &--sale {
      color: $color-dark-gray;
      font-weight: bold;
      margin-right: -8px;
      display: inline-block;
    }
  }
}

#colorbox {
  &.colorbox__quickshop {
    .quickshop__headers {
      max-height: none;
    }
    .quickshop__image-container {
      width: 485px;
    }
  }
}

.mpp__header,
.headline--tertiary,
.spp-product__sub-header,
.spp-product__header,
.headline--page,
.headline--section,
.sign-in-component__header {
  font-family: $optimalight !important;
}

.discover-landing {
  .discover-navigation {
    display: none;
  }
}

.product-full {
  &__price {
    margin: 10px 0 5px;
    font-weight: bold;
  }
}

.spp-reviews {
  .pr-header {
    font-family: $optimalight;
  }
}

.product-full__skintype-select-container,
.spp-product__skintype-select,
.product-brief__skintype-select-container,
.discover-block__subtags {
  display: none;
}

.quickshop-inline {
  &__phone-button,
  &__skintype-select-container {
    display: none;
  }
  &__shade-select-container {
    margin-top: 10px;
  }
}

.spp-product {
  &__shade-select-container {
    margin-top: 10px;
  }
}

.spp-discover-more-section-mobile {
  .discover_more__product {
    .discover_more__product-header,
    .discover_more__product-sub-header {
      font-family: $optimalight;
    }
  }
}

.tabbed-products-block {
  &__header {
    .tabbed-products-font & {
      font-family: $optimalight !important;
    }
  }
}

.el-search-block {
  input[type='text'].form-text {
    font-family: $optimalight !important;
  }
}

.content-formatter__text {
  .content-formatter__line--title {
    font-family: $optimalight;
  }
}

.product-vto {
  &__youcam-module-container {
    .product-vto__mobile-ctrl {
      .container {
        h4 {
          @media #{$medium-down} {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
        .current-shade {
          @media #{$medium-down} {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
.product-foundation-vto {
  &__mobile-ctrl {
    .container {
      .product-foundation-vto__info-container {
        .product-name,
        .product-sub-heading {
          font-size: 12px;
        }
      }
      .product-foundation-vto__btn-container {
        .see-all-shades,
        .see-my-perfect {
          font-size: 12px;
        }
        .product-full__add-button {
          font-size: 12px;
          padding: 0;
        }
      }
    }
  }
  &__overlay-check {
    #vto-disclaimer-checkbox ~ label::before {
      border: 1px solid $color-black;
    }
  }
}

.viewcart-panel {
  .messages {
    .single-message {
      color: $color-navy;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}

.page-branding {
  &__logo-svg {
    @media #{$device-small-only} {
      width: 170px;
    }
  }
}
