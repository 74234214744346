.page-wrapper {
  .account-body {
    .order-status-table {
      &__row--header {
        display: table-row;
      }
    }
  }
  .sign-in-page {
    .social-login {
      &__divider {
        @media #{$large-up} {
          width: 100%;
        }
      }
      &__email-opt-in {
        text-align: #{$ldirection};
        margin-top: 15px;
        padding-#{$ldirection}: 17px;
        padding-#{$rdirection}: 25px;
        label {
          text-transform: none;
          letter-spacing: 0.8px;
        }
      }
      &__terms {
        text-align: #{$ldirection};
        p {
          font-size: 12px;
        }
      }
    }
    .sign-in-component {
      &__header {
        font-family: $optimaregular;
        @media #{$medium-up} {
          letter-spacing: -2px;
        }
      }
      input[data-error] {
        border-color: $color-red;
      }
    }
  }
  .wishlist-page {
    .wishlist_display_item {
      .wishlist_add_to_bag {
        line-height: 1.3;
      }
    }
  }
  .wishlist_row {
    .add_to_bag {
      line-height: 1.5;
    }
  }
  .account-wishlist {
    .button {
      line-height: 1.4;
    }
  }
  .registration-page {
    &__birthday {
      .sex_container {
        margin-#{$ldirection}: 1px;
        width: 100%;
        margin-top: 30px;
      }
    }
    &__personal-info {
      .csrHide {
        margin-top: 25px;
      }
      .headline--section {
        font-family: $optimalight;
      }
      .registration-page__content {
        .form-item--email {
          @media #{$medium-up} {
            clear: both;
            padding-#{$rdirection}: 20px;
          }
        }
      }
    }
  }
  .page-sticky-footer {
    &__left {
      a {
        font-weight: 700;
      }
    }
  }
}

.address-form {
  .main-button-wrapper {
    margin-bottom: 50px;
    @media #{$medium-up} {
      margin-bottom: 0;
    }
  }
  &__fieldset {
    .address-form {
      &__ship-type-container {
        display: block;
      }
    }
  }
}

.lookup_results_container {
  .address_lookup_wrapper {
    border: 1px solid $color-light-gray;
    box-shadow: inset 0 0 5px $color-light-gray;
    clear: both;
    padding: 10px;
    width: 100%;
    height: 200px;
    overflow: auto;
    margin-bottom: 15px;
    .action-close {
      text-decoration: underline;
    }
    .fullwidth {
      width: 100%;
    }
    .action-btn {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.pg_wrapper {
  .account-utilities {
    &__header {
      margin-bottom: 17px;
      font-family: $optimaregular;
    }
  }
  .account-body {
    .account-page__header {
      font-family: $optimaregular;
    }
  }
  .section-head {
    &__header {
      font-family: $optimaregular;
    }
  }
}

#cboxContent {
  .delete-address-confirm {
    &__controls {
      margin-top: 0;
      @media #{$medium-up} {
        margin-top: 20px;
      }
    }
  }
}

.order-details-page {
  .cart-item-table {
    border-top: 1px solid $color-light-gray;
    thead {
      tr {
        th {
          padding-top: 10px;
        }
      }
    }
  }
  .order-details--summary {
    text-align: #{$rdirection};
    .order_summary {
      width: 100%;
      margin-top: 20px;
      td {
        @media #{$medium-up} {
          width: 30%;
          padding-#{$rdirection}: 20px;
        }
        @media #{$large-up} {
          width: 20%;
        }
      }
    }
  }
  .shipment-header {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 10px 20px;
    @media #{$medium-up} {
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
    }
  }
}

.order-history-page {
  .order-status-table {
    .button--order-details {
      margin-top: 20px;
      @media #{$medium-up} {
        margin-top: 0;
      }
    }
  }
}

.signin-overlay-wrapper {
  .social-login {
    &__terms {
      text-align: #{$ldirection};
      p {
        font-size: 12px;
      }
    }
  }
}

.order-timeline {
  margin-top: 20px;
  &__details {
    display: flex;
    margin: auto;
  }
  &__step {
    text-align: center;
    position: relative;
    pointer-events: none;
    opacity: 0.3;
    flex: 1;
    + .order-timeline__step {
      margin-#{$ldirection}: 4px;
      @media #{$medium-up} {
        margin-#{$ldirection}: 25px;
      }
      &::before {
        content: '';
        border-top: 3px solid $color-navy;
        width: 80%;
        position: absolute;
        top: 10px;
        transform: translate(calc(-100% - 11px));
        @media #{$medium-up} {
          width: 100%;
          transform: translate(calc(-100% - 13px));
        }
      }
    }
  }
  &__step_active {
    opacity: 1;
    pointer-events: auto;
    .order-timeline {
      &__step-bullet {
        background-color: $color-navy;
      }
    }
  }
  &__step-bullet {
    background-color: $color-white;
    border: 1px solid $color-navy;
    border-radius: 100%;
    font-size: 14px;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px;
    width: 25px;
    height: 25px;
  }
  &__step-title {
    font-size: 14px;
    max-width: none;
    margin: auto;
  }
  &__step-subtitle {
    font-family: inherit;
    font-size: 10px;
  }
  &__step_cancel {
    .order-timeline {
      &__step-bullet {
        background-color: $color-strong-red;
      }
    }
    &.order-timeline__step_active::before {
      width: 100%;
      z-index: -1;
    }
  }
}

.device-mobile {
  .footer-main-menu {
    .menu {
      #footer_live_chat {
        .need-help-chat-now {
          padding-left: 0;
        }
      }
    }
  }
}

.order-status-table {
  &__details {
    .mpp__product-grid {
      .text--bold {
        font-weight: normal;
      }
    }
  }
}

#registration {
  .profile-password-update__fieldset {
    .profile-password-update__rules {
      padding: 0;
      @media #{$medium-up} {
        padding: 10px;
      }
      li {
        white-space: wrap;
      }
    }
  }
}
