#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-main-header-search {
          min-width: 280px;
          width: 35%;
          padding-top: 15px;
          margin-#{$ldirection}: 16px;
          &-sort {
            width: 100%;
            @media #{$medium-up} {
              width: 53%;
            }
          }
        }
        .pr-rd-review-header-sorts {
          @media #{$small-only} {
            width: 120%;
            padding-#{$ldirection}: 0;
            .pr-rd-sort-group {
              left: 30px;
            }
          }
        }
        .pr-rd-review-total {
          display: block;
          width: 100%;
          margin-top: 0;
          @media #{$medium-up} {
            margin-#{$ldirection}: 50px;
          }
        }
      }
    }
  }
}
