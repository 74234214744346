.page-wrapper {
  .checkout {
    @media #{$large-up} {
      margin: -82px auto 0;
    }
    &__content {
      @media #{$large-up} {
        width: 64.84375%;
      }
      .viewcart-panel {
        .cart-item {
          &__qty {
            margin-top: 10px;
          }
        }
        .viewcart-buttons-panel {
          .continue-buttons {
            .choose-samples-and {
              display: none;
            }
          }
        }
      }
      .social-login {
        &__opt-in-label {
          font-size: 15px;
        }
        &__terms {
          padding-#{$ldirection}: 0;
        }
      }
      .payment-display {
        .address {
          white-space: nowrap;
          margin-bottom: 20px;
        }
      }
      .shipping-address-display {
        .edit {
          margin-top: 20px;
          margin-bottom: 20px;
          @media #{$medium-up} {
            margin-bottom: 0;
          }
        }
      }
      .recommended-products-panel {
        &__content {
          .btn {
            line-height: 1.5;
          }
        }
        .recommended-products {
          &__list {
            .sku-brief-editorial {
              height: 500px;
              flex: 1;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
            }
          }
        }
      }
    }
    &__return-user {
      @media #{$large-up} {
        border-#{$ldirection}: 0;
      }
    }
    &__new-account {
      @media #{$large-up} {
        border-#{$rdirection}: 1px solid $color-light-gray;
      }
      .create-account {
        text-align: #{$ldirection};
        .btn {
          margin-top: 20px;
        }
      }
    }
    &__return-user,
    &__new-account {
      @media #{$large-up} {
        padding-bottom: 0;
      }
    }
    .guarantee-panel {
      @media #{$large-up} {
        margin-top: 0;
      }
    }
    &__sidebar {
      width: 100%;
      @media #{$large-up} {
        width: 35.15625%;
      }
      &::after {
        width: 35.15625%;
        #{$ldirection}: 64.84375%;
      }
      .panel {
        @media #{$large-up} {
          margin-top: 0;
          margin-bottom: 32px;
        }
        .viewcart-cpf-field {
          margin-top: 0;
          border: none;
          padding-top: 0;
          @media #{$large-up} {
            margin-bottom: 0;
          }
        }
      }
      .viewcart-panel {
        .viewcart-header {
          padding-bottom: 25px;
        }
        &__title {
          a {
            width: 28%;
            float: #{$ldirection};
          }
        }
      }
      .cart-item {
        &__desc-info {
          width: 60%;
        }
      }
    }
    &.samples-page {
      @media #{$large-up} {
        margin-bottom: 75px;
      }
      .samples-panel__content {
        .samples-top {
          .remaining {
            @media #{$large-up} {
              padding-#{$ldirection}: 180px;
            }
          }
        }
        .samples {
          .samples-title {
            font-size: 30px;
            @media #{$large-up} {
              font-size: 40px;
              margin-top: 15px;
            }
          }
          .product-list {
            @media #{$large-up} {
              display: flex;
              flex-wrap: wrap;
            }
            .product {
              @media #{$large-up} {
                float: none;
                position: relative;
              }
            }
          }
        }
      }
    }
    .address-form {
      width: 100%;
      @media #{$large-up} {
        width: 85%;
      }
      &__address5-container {
        margin-bottom: 0;
      }
      &__state-container {
        margin-top: 20px;
      }
      .form-item {
        padding-#{$rdirection}: 20px;
        @media #{$large-up} {
          padding-#{$ldirection}: 20px;
          padding-#{$rdirection}: 0;
        }
      }
      .default-bill_to_shipping {
        label {
          display: block;
        }
      }
    }
    .adpl {
      .address-form {
        &__state-container {
          margin-top: 0;
        }
      }
    }
    .gift-options {
      &__content {
        .sub-section {
          .form-item {
            position: static;
          }
        }
      }
    }
  }
  .order-summary {
    &__shipping-method,
    &__shipping {
      display: block;
    }
    &__stock_items_only {
      text-align: #{$ldirection};
      line-height: 1.5;
    }
  }
  .checkout-page-title {
    letter-spacing: -2.5px;
  }
  &.enhanced-cart-page {
    .enhanced_mobile_hidden {
      display: none !important;
    }
  }
}

.order-summary-panel {
  .order-summary {
    &__content {
      .label,
      .value {
        line-height: 1.5;
        margin: 0 0 6px;
      }
      .label {
        width: 70%;
        float: #{$ldirection};
        margin-top: 2%;
        text-align: #{$ldirection};
      }
      .value {
        float: #{$rdirection};
        width: 30%;
        text-align: #{$rdirection};
      }
    }
    &__shipping {
      width: 100%;
      .label {
        width: 100%;
      }
      .ship-method-select {
        width: 320px;
      }
      .postcode-zipfields {
        float: #{$rdirection};
        width: 58%;
        margin-bottom: 5%;
      }
    }
    &__shipping-method {
      float: #{$ldirection};
    }
  }
}

.active-panel-signin {
  .page-wrapper {
    .checkout-index-header {
      margin-bottom: 0;
    }
  }
  .checkout-progress-bar {
    display: none;
  }
  .checkout__content {
    .social-login {
      &__email-opt-in {
        padding-#{$ldirection}: 0;
      }
      &__divider {
        width: 100%;
      }
    }
  }
}

#confirm {
  .checkout {
    .social-login {
      padding: 0;
      &__email-opt-in {
        margin-top: 15px;
        padding-#{$ldirection}: 0;
      }
    }
    .confirmation-panel {
      &__print-buttons {
        .print-confirm {
          line-height: 1.5;
          padding: 10px 30px;
          font-size: 11px;
          @media #{$medium-up} {
            line-height: 3.4;
            padding: 0 30px;
            font-size: 12px;
          }
        }
      }
    }
  }
}

#checkout_payment_review {
  .radio {
    .related-media {
      @media #{$medium-up} {
        display: inline-block;
        margin-#{$ldirection}: 10px;
      }
    }
  }
  .chckt-sdk {
    margin-top: 20px;
    .chckt-button-container {
      margin-bottom: 20px;
    }
    .chckt-form-label--exp-date {
      width: 40%;
      .chckt-form-label {
        &__text {
          white-space: nowrap;
        }
      }
    }
    .chckt-form-label--cvc {
      width: 40%;
      @media #{$medium-up} {
        width: 55%;
      }
    }
  }
  .local-submits {
    .checkout_sticky {
      display: none;
    }
  }
}

.reciept-section {
  .order-details-page-reciept {
    .sidebar-page {
      &__content {
        @media #{$medium-up} {
          width: 100%;
          padding: 165px 0 0;
        }
        .page-footer {
          margin-top: 150px;
        }
        .order-details--summary {
          .order_summary {
            text-align: #{$rdirection};
            @media #{$medium-up} {
              margin-top: 0;
            }
          }
        }
        .order-summary {
          padding: 0 20px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.checkout-progress-bar {
  margin: 18px 0;
  overflow: hidden;
  &__list {
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style-type: none;
  }
  &__list-item {
    position: relative;
    float: #{$ldirection};
    width: 33.33%;
    color: $color-light-gray;
    font-size: 14px;
    text-align: center;
    &--status {
      color: $color-light-gray;
    }
    &::before {
      border-radius: 14px;
      display: block;
      margin: 0 auto;
      margin-bottom: 6px;
      width: 14px;
      height: 14px;
      background: $color-light-gray;
      border: 1px solid $color-light-gray;
      color: $color-light-gray;
      content: '';
      line-height: 1.1;
    }
    &::after {
      position: absolute;
      top: 6px;
      #{$rdirection}: 50%;
      width: 100%;
      height: 2px;
      background: $color-light-gray;
      content: '';
      z-index: -1;
    }
    &:first-child::after {
      content: none;
    }
  }
}

.active-panel-shipping,
.active-panel-registration {
  .checkout-progress-bar {
    &__list-item {
      &--shipping {
        font-size: 18px;
        &::before {
          background: $color-navy;
        }
        .shipping-progress {
          color: $color-navy;
        }
      }
    }
  }
}

.active-panel-payment {
  .checkout-progress-bar {
    &__list-item {
      &--payment {
        font-size: 18px;
        .payment-progress {
          color: $color-navy;
        }
        &::before {
          background: $color-navy;
        }
      }
      &--shipping {
        &::before {
          background: $color-navy;
        }
      }
    }
  }
}

.active-panel-review {
  .checkout-progress-bar {
    &__list-item {
      &--review {
        font-size: 18px;
        .review-progress {
          color: $color-navy;
        }
      }
      &--shipping,
      &--payment,
      &--review {
        &::before {
          background: $color-navy;
        }
      }
    }
  }
}

.security-overlay {
  #customer-service {
    width: auto;
    border: none;
    padding: 0;
    .customer-service {
      &-header {
        font-size: 45px;
      }
    }
  }
}

//Sticky Checkout
.page-wrapper-checkout {
  padding-bottom: 100px;
  @media #{$large-up} {
    padding-bottom: 0;
  }
  .checkout {
    .continue-button-wrapper {
      @include box-shadow(0 -3px 6px $color-light-gray);
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      text-align: center;

      a.btn,
      input.btn {
        display: block;
        width: 100%;
        background-color: $color-navy;
        color: $color-white;
        font-weight: normal;

        @media #{$large-up} {
          font-weight: bold;
        }
      }

      ///
      /// Undo Sticky on Desktop
      ///
      @media #{$large-up} {
        position: static;
        box-shadow: none;
        width: 50%;
        height: auto;
        float: right;
        padding: $checkout-spacing--large $checkout-spacing--small $checkout-spacing--large 0;
      }
    }
  }
}
