.enhanced-cart-page .checkout {
  .panel--offer-code {
    margin: 0;
    padding: 0;
    border-top: none;
    border-bottom: $checkout-line;
    &__header {
      padding: $checkout-spacing--large $checkout-spacing--extra-large;
      &--secondary {
        @include hidden;
      }
    }
    .offer-code {
      &__byline {
        display: block;
        position: relative;
        margin-bottom: 0;
        padding: $checkout-spacing--large $checkout-spacing--extra-large;
        @media #{$large-up} {
          display: none;
        }
        &.open {
          padding-bottom: 0;
          .title-icon {
            background-size: auto auto;
            background-position: 0 -2957px;
            height: 53px;
            width: 53px;
          }
        }
      }
      &__one-offer-only {
        margin-top: $checkout-spacing--large;
      }
      &__messages {
        margin-bottom: $checkout-spacing--medium;
        color: $color-periwinkle-blue;
        font-size: 12px;
        line-height: 1.5;
      }
      &__form {
        @include clearfix;
        padding: $checkout-spacing--large $checkout-spacing--extra-large;
        form {
          @include clearfix;
          padding-top: 6px;
        }
        input {
          width: 100%;
          height: 4em;
          margin-top: 0;
          @media #{$large-up} {
            height: 3.75em;
          }
        }
        .btn.form-submit {
          float: right;
          display: flex;
          justify-content: center;
          width: 40%;
          color: $color-white;
          background-color: $color-navy;
          border-color: $color-navy;
          font-size: 11px;
        }
      }
      &__code {
        float: left;
        padding-right: $checkout-spacing--large;
        width: 60%;
        height: 4em;
        .offer-code-field {
          border-color: $color-light-gray;
        }
      }
    }
  }

  &__sidebar {
    .panel--offer-code .offer-code {
      &__byline--secondary {
        display: block;
      }
    }
    .offer-code-panel__content {
      letter-spacing: 0.05em;
    }
  }
}

body.elc-user-state-loyalty .page-wrapper-checkout .checkout {
  .checkout__offer-code__wallet {
    border-bottom: none;
  }
  .offer-code {
    &__byline {
      border-top: $checkout-line;
    }
  }
}