.enhanced-cart-page {
  .viewcart {
    .sample-list {
      .sample {
        &__shade-name {
          font-family: $optimaregular;
          margin-bottom: 5px;
          @media #{$medium-up} {
            font-size: 14px;
          }
        }
      }
    }
  }
}
